<template>
  <div>
    <vx-card>
      <h4 class="mb-4">
        {{ $t(resources.Filters.i18n) || resources.Filters.name }}
      </h4>
      <div class="vx-row mb-2">
        <div class="vx-col w-full md:w-1/5 mb-2">
          <label class="vs-input--label">
            {{ $t(resources.StartDate.i18n) || resources.StartDate.name }}
          </label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="start_date"
          ></datepicker>
        </div>
        <div class="vx-col w-full md:w-1/5 mb-2">
          <label class="vs-input--label">
            {{ $t(resources.EndDate.i18n) || resources.EndDate.name }}
          </label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="end_date"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/5 w-full mb-2">
          <label class="vs-input--label">{{
            $t(resources.CurrencyCode.i18n) || resources.CurrencyCode.name
          }}</label>
          <v-select
            v-model="currency"
            :clearable="true"
            :options="currencyList"
            :reduce="currency => currency.id"
            label="display"
          />
        </div>
        <div class="vx-col w-full md:w-1/5 mb-2">
          <vs-select
            class="vx-col w-full"
            :label="$t(resources.Type.i18n) || resources.Type.name"
            v-model="type"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="$t(item.i18n) || item.text"
              v-for="(item, index) in typeList"
            />
          </vs-select>
        </div>

        <div class="vx-col w-full mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="get()">
            {{ $t(resources.Search.i18n) || resources.Search.name }}
          </vs-button>
        </div>
      </div>
    </vx-card>

    <div id="ag-grid-demo" style="padding-top: 7px">
      <vx-card>
        <div class="flex flex-wrap justify-between items-center">
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    dataList.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : dataList.length
                  }}
                  of {{ dataList.length }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
              CVS
            </vs-button>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="dataList"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import resources from "@/i18n/resources.js";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import vSelect from "vue-select";
import moment from "moment";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    Datepicker,
    vSelect
  },
  data() {
    return {
      resources: resources,
      language: this.$i18n.locale,
      languages: lang,
      currencyList: [],
      currency: "",
      start_date: new Date(),
      end_date: new Date(),
      gridApi: null,
      rows: 20,
      type: 2,
      typeList: [
        {
          text: "All",
          value: "",
          i18n: "All"
        },
        {
          text: "Tourism",
          value: "1",
          i18n: "Tourism"
        },
        {
          text: "Commissions",
          value: "2",
          i18n: "Commissions"
        },
        {
          text: "Customized",
          value: "3",
          i18n: "Customized"
        }
      ],
      dataList: [],
      searchQuery: "",
      value: "",
      emailSession: JSON.parse(localStorage.getItem("userInfo")).email,
      maxPageNumbers: 10,
      NumberPage: 0,
      gridOptions: {},
      popupCreate: false,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("Type"),
          field: "typeName",
          filter: true,
          width: 210
        },
        {
          headerName: this.$i18n.t("Date"),
          field: "date",
          filter: true,
          width: 210,
          cellRenderer: function(event) {
            return moment(event.node.data.date).format("YYYY-MM-DD");
          }
        },
        {
          headerName: this.$i18n.t("CurrencyCode"),
          field: "currencyCode",
          filter: true,
          width: 180
        },
        {
          headerName: this.$i18n.t("Value"),
          field: "value",
          filter: true,
          width: 220
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "creationDate",
          filter: true,
          width: 200,
          cellRenderer: function(event) {
            return moment(event.node.data.creationDate).format(
              "YYYY-MM-DD hh:mm:ss"
            );
          }
        }
      ]
    };
  },
  async created() {
    await this.getCurrency();
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.totalNumberPages)
        return this.totalNumberPages.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.NumberPage = val;
        if (
          localStorage.getItem("tokenVT") != null &&
          localStorage.getItem("tokenVT") != undefined
        ) {
          this.get();
        }
      }
    }
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async getCurrency() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Currency/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.currencyList = [];
          } else this.currencyList = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async get() {
      this.$vs.loading();
      let filterData = {};
      let startDate = "";
      let endDate = "";

      if (this.start_date != null && this.end_date != null) {
        startDate = moment(this.start_date).format("YYYY-MM-DD");
        endDate = moment(this.end_date).format("YYYY-MM-DD");

        filterData = {
          startDate: startDate,
          endDate: endDate
        };
      }

      if (this.currency != "" && this.currency != null && this.currency != 0) {
        this.$set(filterData, "currencyId", this.currency);
      }

      if (this.type != "" && this.type != null && this.type != 0) {
        this.$set(filterData, "type", this.type);
      }

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}ExchangeRate/ListByFilters`,
        data: {
          searchKeys: filterData,
          page: this.NumberPage,
          rows: this.rows,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        result => {
          if (result.data.success) {
            this.dataList = result.data.data;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.dataList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    mounted() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
    }
  }
};
</script>
